import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";

import { FormGrid, FormRow, FormElement, FormMessage } from "./form-elements";
import { Button, InternalLinkButton, InternalLinkButtonWhite } from "./button-elements";
import { Block, BlockSeparator, BlockTitle, BlockParagraph } from "./block-elements";
import { PageWrapper } from "./wrapper-elements";
import TextBox from "./text-box";
import ReactJson from 'react-json-view'
import theme from "./theme";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 96px;
`;

const OutputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 48px;
`;

const OutputBox = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  background-color: ${theme.color.background};
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 900px;
`;

const Output = styled.pre`
  box-sizing: border-box;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 48px;
  padding-right 48px;
  line-height: 1.5;
  color: ${theme.color.text};
`;

const Document = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 48px;
  width: 900px;
`;

const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 350;
  line-height: 1.5;
  width: 900px;
  color: ${theme.color.text};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
`;

const Chip = styled.div`
  font-size: 12px;
  font-weight: 450;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  color: ${theme.color.text};
  border: 1.5px solid ${theme.color.text};
  border-radius: 50px;
  margin-bottom: 8px;
  margin-right: 8px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: ${theme.color.text};
    color: white;
  }
`;

const Citation = styled.div`
  font-size: 12px;
  font-weight: 350;
  color: ${theme.color.text};
  opacity: 0.5;
  margin-top: 12px;
  line-height: 1;
`;

const Division = styled.span`
  padding-right: 16px;
  font-size: 15px;
  font-weight: 600;
`;

const ParagraphBox = styled.div`
  display: flex;
  align-items: stretch;
  background-color: ${theme.color.background};
  border-radius: 8px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  border: 1.5px solid ${theme.color.background};
  transition: 0.2s;
  &:hover {
    border: 1.5px solid ${theme.color.text};
  }
`;

const ChipBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 8px;
  padding-left: 16px;
`;

const ChipBoxLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 8px;
  padding-left: 16px;
`;

const OutputLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 12px;
`;

const Code = styled.span`
  background: ${theme.color.background};
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  color: rgba(0, 53, 128, 1);
  font-family: monospace;
  font-size: 15px;
`;

const Items = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 700px;
  padding-left: 24px;
`;

const Item = styled.li`
  font-size: 16px;
  margin-bottom: 12px;
  color: ${theme.color.text};
  font-weight: 350;
`;

export default function App() {

  const default_query = '/databases/text-corpus/eu-law/JUDG_CJ_2021_0680'
  const default_api_key = ''
  // const API = 'http://localhost:4000'
  const API = 'https://api.lawlibrary.ai'

  const [query, setQuery] = React.useState(default_query);
  const [key, setKey] = React.useState(default_api_key);
  const [data, setData] = React.useState(undefined);
  const [message, setMessage] = React.useState('');

  const getData = () => {
    setData(undefined)
    Axios.get(API + query, {
      headers: {
        'x-api-key': key,
      }
    })
      .then(response => {
        setData(response.data.data);
        console.log(response.data.data);
      })
      .catch(error => {
        console.log(error);
        // setMessage(error.response.data.error.message)
      });
  }

  const capitalize = (val) => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  const cleanDivisionLabel = (label) => {

    const labelsToExclude = ['None', 'Recital', 'Unknown', 'Paragraph', 'Unnumbered Paragraph', 'Indent', 'Continuation', 'Heading']

    if (labelsToExclude.includes(label)) {
      label = ''
    }
    // label = capitalize(label)
    return label + ' '
  }

  const cleanText = (text) => {
    text = text.replaceAll('<sl-cite>', '<span class="tag">')
    text = text.replaceAll('</sl-cite>', '</span>')
    text = text.replaceAll('<pl-cite>', '<span class="tag">')
    text = text.replaceAll('</pl-cite>', '</span>')
    text = text.replaceAll('<cl-cite>', '<span class="tag">')
    text = text.replaceAll('</cl-cite>', '</span>')
    return text
  }

  const uniqueBy = (arr, key) => {
    const seen = new Set();
    return arr.filter(item => {
      const value = item[key];
      if (!seen.has(value)) {
        seen.add(value);
        return true;
      }
      return false;
    });
  }

  const makeActorTags = (data) => {
    let tags = null;
    if (data.length > 0) {
      let uniqueData = uniqueBy(data, 'label')
      tags =
        <div>
          <ChipBoxLabel>
            Actors
          </ChipBoxLabel>
          <ChipBox>
            {uniqueData.map((tag, i) => {
              return (
                <Chip>
                  {tag.label}
                </Chip>
              )
            })}
          </ChipBox>
        </div>
    }
    return tags
  }

  const makePincite = (data) => {
    let tags = null;
    if (data) {
      tags =
        <div>
          <ChipBoxLabel>
            Pincite
          </ChipBoxLabel>
          <ChipBox>
            {/* {data.subdivision.elements.map((element, i) => {
              return (
                <Chip>
                  {element.label}
                </Chip>
              )
            })} */}
            <Chip>
              Label: {data.line.subdivision.label}
            </Chip>
            <Chip>
              ID: {data.line.subdivision.subdivision_id}
            </Chip>
            <Chip>
              Section: {data.line.document_section}
            </Chip>
            <Chip>
              Line type: {data.line.line_type}
            </Chip>
            <Chip>
              Line: {data.line.index}
            </Chip>
            <Chip>
              Depth: {data.line.line_depth}
            </Chip>
            <Chip>
              Prefix: {data.line.subdivision_element.prefix}
            </Chip>
            <Chip>
              Pattern: {data.line.subdivision_element.pattern}
            </Chip>
            {/* <Chip>
              Counter {data.line.counter}
            </Chip> */}
          </ChipBox>
        </div>
    }
    return tags
  }

  const makeCitations = (data) => {
    let tags = null;
    if (data.length > 0) {
      tags =
        <div>
          <ChipBoxLabel>
            Citations
          </ChipBoxLabel>
          <ChipBox>
            {data.map((tag, i) => {

              let document_part = null
              if (tag.document) {
                if (tag.document.id)
                document_part = tag.document.law_library_id.law_library_id
              }

              return (
                <Chip>
                  {document_part}
                </Chip>
                // <Chip>
                //   {tag.subdivision ? tag.subdivision.match_id : ''}
                // </Chip>
              )
            })}
          </ChipBox>
        </div>
    }
    return tags
  }

  let messageBox = null
  if (message != '') {
    <div>
      {message}
    </div>
  }

  let output;
  let text;
  if (data) {
    output =
      <Output>
        {JSON.stringify(data, null, 2)}
      </Output>

    if (!query.includes('?') && data.length > 0) {
      text =
        <Block backgroundColor='white'>
          <Document>
            <OutputLabel>
              Formatted output
            </OutputLabel>
            {data.map((item, i) => {
              return (
                <>
                  <Paragraph key={i} style={{ paddingLeft: (item.line.line_depth - 1) * 32 }}>
                    <Column>
                      <ParagraphBox>
                        <span>
                          {item.line.subdivision_element.value.raw == null ? null : <Division>{cleanDivisionLabel(item.line.subdivision_element.prefix)}{item.line.subdivision_element.value.raw}</Division>}
                          {/* <span dangerouslySetInnerHTML={{ __html: cleanText(item.text) }} /> */}
                          <span>{item.text.raw}</span>
                        </span>
                      </ParagraphBox>
                      {makePincite(item)}
                      {/* {makeCitations(item.annotations.citations)} */}
                      {/* {makeActorTags(item.annotations.actor_tags)} */}
                    </Column>
                  </Paragraph>
                </>
              )
            })}
          </Document>
        </Block>
    }

    output =
      <OutputWrapper>
        <OutputLabel>
          JSON output
        </OutputLabel>
        <OutputBox>
          <ReactJson
            src={data}
            name={'results'}
            displayObjectSize={false}
            displayDataTypes={true}
            enableClipboard={false}
            collapsed={2}
            theme={{
              base00: theme.color.background,
              base01: "red",
              base02: "rgba(40, 40, 45, 0.0)",
              base03: "red",
              base04: "rgba(40, 40, 45, 0.3)",
              base05: "red",
              base06: "red",
              base07: "rgba(3, 27, 78, 1)",
              base08: "red",
              base09: "rgba(160, 30, 160, 1)",
              base0A: "rgba(0, 105, 255, 1)",
              base0B: "red",
              base0C: "rgba(40, 40, 45, 0.5)",
              base0D: "rgba(40, 40, 45, 0.3)",
              base0E: "rgba(40, 40, 45, 0.3)",
              base0F: "rgba(0, 105, 255, 1)",
            }}
          />
        </OutputBox>
      </OutputWrapper>
  }

  return (
    <PageWrapper>
      <Block backgroundColor='white'>
        <BlockTitle>
          API demo
        </BlockTitle>
        <BlockParagraph>
          The LawLibrary.AI API provides access to our database of EU legal documents. To try out the API, you'll need an API key. Please contact us to get more information about trying out our API and to request an API key.
        </BlockParagraph>
        <BlockParagraph>
          The API provides data in a JSON format. This page provides a convenient interface for trying out the API. You can inspect the JSON output and view a formated version of the document based on the data.
        </BlockParagraph>
        <BlockTitle>
          Getting Started
        </BlockTitle>
        <BlockParagraph>
          You'll need an API key to use the LawLibrary.AI API. An API key consists of seven groups of five letters and numbers separated by dashes. If you want to access the data from another application or using another method, you can fire a <Code>GET</Code> request at the main endpoint. To authenticate with the API, you'll need to provide your API key in the header of your GET request using the <Code>x-api-key</Code> field. API requests are encrypted using HTTPS/SSL.
        </BlockParagraph>
        <BlockParagraph>
          Our API allows you to download data for individual documents or lines of documents. The main API endpoint is <Code>/database/text-corpus/eu-law/:id</Code>, where <Code>:id</Code> is the ID of the document. This is a required parameter. We use our own ID numbers because CELEX numbers and ECLI numbers aren't always uniquely identifying.
        </BlockParagraph>
        <BlockParagraph>
          There are several optional API parameters. To specify an optional parameter, you'll need add a <Code>?</Code> after the route followed by the parameter name, an <Code>=</Code>, and the parameter value. To specify multiple parameters, you'll need to separate them with a <Code>&</Code>. You don't need to include another <Code>?</Code>. If you need to specify multiple values for a parameter, you can separate them with a <Code>,</Code>.
        </BlockParagraph>
        <BlockParagraph>
          You can use the <Code>include</Code> parameter to select specific JSON fields to include in the output. Any fields not specifically selected will be omitted. If a field contains a nested object, selecting the field will also select all data in the nested object. To select a nested field, you'll need concatenate the fields with a <Code>.</Code>. For example, if the field <Code>id</Code> is nested within the field <Code>document</Code>, you can select <Code>id</Code> using the value <Code>document.id</Code>. For example, you could use <Code>include=document.id,subdivision.id,text.english.plain</Code> to get the document ID, the subdivision ID, and the text of the line.
        </BlockParagraph>
        <BlockParagraph>
          Conversely, you can use the <Code>exclude</Code> parameter to select JSON fields to exclude from the output. For example, you could use <Code>exclude=annotations</Code> to omit annotations. You can't use <Code>include</Code> and <Code>exclude</Code> at the same time. If you try to use both, <Code>include</Code> will take precedence and the API will ignore the <Code>exclude</Code> value.
        </BlockParagraph>
        <BlockParagraph>
          The demo includes the following documents:
        </BlockParagraph>
        <Items>
          <ul>
            <Item>The Treaty on the Functioning of the European Union: <Code>TR_TFEU_2016</Code></Item>
            <Item>Directive 96/9/EC (the database directive): <Code>DIR_1996_0009</Code></Item>
            <Item>Directive 2006/123/EC (the services directive): <Code>DIR_2006_0123</Code></Item>
            <Item>Directive (EU) 2016/680 (the data retention directive): <Code>DIR_2016_0680</Code></Item>
            <Item>Regulation (EU) 2016/679 (the GDPR): <Code>REG_2016_0679</Code></Item>
            <Item>The judgment of 27 November 2012 in Case C-370/12: <Code>JUDG_CJ_2012_0370</Code></Item>
            <Item>The judgment of 21 December 2023 in Case C-333/21: <Code>JUDG_CJ_2021_0333</Code></Item>
          </ul>
        </Items>
        <InputWrapper>
          <FormElement>
            <TextBox
              value={query}
              onChange={(value) => {
                setQuery(value)
                setData(undefined)
              }}
              placeholder=""
              type="text"
              label="API query"
              width="900px"
            />
          </FormElement>
          <FormElement>
            <TextBox
              value={key}
              onChange={setKey}
              placeholder=""
              type="text"
              label="API key"
              width="900px"
            />
          </FormElement>
          <FormElement>
            <Button primary onClick={() => getData()}>
              Run query
            </Button>
          </FormElement>
          {messageBox}
        </InputWrapper>
        {output}
        {text}
      </Block>
    </PageWrapper>
  );
};
