import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";

import { FormGrid, FormRow, FormElement, FormMessage } from "./form-elements";
import { Button, InternalLinkButton, InternalLinkButtonWhite } from "./button-elements";
import { Block, BlockSeparator, BlockTitle, BlockParagraph } from "./block-elements";
import { PageWrapper } from "./wrapper-elements";
import TextBox from "./text-box";
import ReactJson from 'react-json-view'
import theme from "./theme";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 48px;
`;

const OutputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 48px;
`;

const OutputBox = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 350;
  background-color: ${theme.color.background};
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 900px;
  width: 350px;
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    width: calc(${theme.breakpoints.tablet} - 48px - 24px)
  }
`;

const OutputLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 12px;
`;

const Spinner = styled.div`
  border: 8px solid ${theme.color.background};
  border-top: 8px solid ${theme.color.primary};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-top: 24px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default function App() {

  const default_query = ''
  const default_api_key = ''
  // const API = 'http://localhost:4000'
  const API = 'https://api.lawlibrary.ai'

  const [query, setQuery] = React.useState(default_query);
  const [key, setKey] = React.useState(default_api_key);
  const [data, setData] = React.useState(undefined);
  const [thinking, setThinking] = React.useState(false);

  const getData = () => {
    setThinking(true)
    setData(undefined)

    const requestBody = {
      question: query
    };

    Axios.post(API + '/assistant', 
      requestBody, 
      {
        headers: {
          'x-api-key': key,
        }
      })
      .then(response => {
        setData(response.data.answer);
        setThinking(false)
      })
      .catch(error => {
        console.log(error);
      });
  }

  let spinner;
  if (thinking) {
    spinner = <Spinner/>
  }

  let button;
  if (!thinking) {
    button = 
      <FormElement>
        <Button primary onClick={() => getData()} disabled={thinking}>
          Ask question
        </Button>
     </FormElement>
  }

  let output;
  if (data) {

    output =
      <OutputWrapper>
        <OutputLabel>
          Answer
        </OutputLabel>
        <OutputBox>
          {data}
        </OutputBox>
      </OutputWrapper>
  }

  return (
    <PageWrapper style={{minHeight: 700}}>
      <Block backgroundColor='white'>
        <BlockTitle>
          Legal Assistant Demo
        </BlockTitle>
        <BlockParagraph>
          The LawLibrary.AI Legal Assistant is an experimental AI-powered research tool for lawyers. To try out the Legal Assistant, you'll need an API key. Please contact us to get more information about trying out our API and to request an API key.
        </BlockParagraph>
        <InputWrapper>
            <FormElement>
              <TextBox
                value={key}
                onChange={setKey}
                placeholder=""
                type="text"
                label="API key"
                dynamic={true}
              />
            </FormElement>
            <FormElement>
            <TextBox
              value={query}
              onChange={(value) => {
                setQuery(value)
                setData(undefined)
              }}
              placeholder="What can I help you with?"
              type="text"
              label="Question"
              dynamic={true}
            />
          </FormElement>
          {button}
        </InputWrapper>
        {spinner}
        {output}
      </Block>
    </PageWrapper>
  );
};
