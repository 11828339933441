import styled from "styled-components";
import theme from "./theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 350px;
  width: calc(${theme.breakpoints.phone} - 48px);
  @media only screen and (min-width: ${theme.breakpoints.tablet}) {
    width: calc(${theme.breakpoints.tablet} - 48px - 24px)
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.color.label};
  margin-bottom: 12px;
  &::before {
    content: '';
    display: block;
    margin-top: -3px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -3px;
  }
`;

const TextBox = styled.input`
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 350;
  line-height: 1;
  color: ${theme.color.text};
  background-color: ${theme.color.background};
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1.5px solid ${theme.color.background};
  border-radius: 8px;
  outline: none;
  &:hover {
    border: 1.5px solid ${theme.color.line_dark};
  }
  &:focus {
    border: 1.5px solid ${theme.color.line_dark};
  }
  &::placeholder {
    font-family: Inter;
    font-weight: 350;
    color: ${theme.color.placeholder};
  }
  width: 100%;
`;

const Note = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${theme.color.text_light};
  margin-top: 12px;
  line-height: 1.5;
  &::before {
    content: '';
    display: block;
    margin-top: -5px;
  }
  &::after {
    content: '';
    display: block;
    margin-bottom: -5px;
  }
`;

const TextBoxComponent = (props) => {
  let { type, value, label, onChange, note, placeholder, width, dynamic } = props;

  if (!dynamic) {
    dynamic = false
  }

  if (!width && !dynamic) {
    width = '340px'
  }

  let labelObject;
  if (label) {
    labelObject =
      <Label>
        {label}
      </Label>
  }

  let noteObject;
  if (note) {
    noteObject = 
    <Note>
      {note}
    </Note>
  }

  return (
    <Wrapper style={{width: width}}>
      {labelObject}
      <TextBox placeholder={placeholder} type={type} value={value} onChange={(e) => onChange(e.target.value)} />
      {noteObject}
    </Wrapper>
  )
}

export default TextBoxComponent;
